@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Exo:ital,wght@0,100..900;1,100..900&display=swap');
:root {
    --main-bg-color: #FD6E47;
    --main-color:'transparent'
  }

.noselect {
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}
*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html{
    font-family: "Exo", sans-serif;
  }
  .disabledBtn{
    width: 100%;
    padding: 20px 30px; 
    border: none;
    background-color: lightgray;
    color:black;
    border-radius: 50px;
    font-size : 16px;
    line-height: 20px;
    font-weight: 600;
  }
   .primaryBtn{
    width: 100%;
    padding: 20px 30px; 
    border: 1px solid var(--main-bg-color);
    background-color: var(--main-bg-color);
    color:white;
    border-radius: 50px;
    font-size : 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .primaryBtn:hover{
    width: 100%; 
    padding: 20px 30px;  
    border: 1px solid var(--main-bg-color);
    color: var(--main-bg-color);
    background-color: transparent; 
    line-height: 20px;
    font-size : 16px;
    font-weight: 400;
  }

  .secondaryBtn{
    width: 100%; 
    padding: 20px 30px; 
    border: 1px solid var(--main-bg-color);
    color: var(--main-bg-color);
    background-color: var(--main-color); 
    border-radius: 50px;
    font-size : 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .secondaryBtn:hover{
    width: 100%; 
    padding: 20px 30px; 
    border: 1px solid var(--main-bg-color);
    color: #ffff;
    background-color: var(--main-bg-color); 
    font-size : 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .topHead{
    width: 100%;
    height: 8vh;
    background-color: var(--main-bg-color);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  
  .mainContainer{
    margin: 0% 5% ;
    overflow: scroll;
    margin-bottom: 50px;
  }
  .slider-wrapper{
    overflow: hidden;
  }

  .heading{
    width: 100%;
    height: 29px;
    color: white;
    font-size: 24px;
    text-align: center;
  }

  .searchContainer{
      padding:15px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  input.searchField.form-control{
    width:100%;
    border:none;
    line-height: 30px;
    font-size: 20px;

    }
    .about h6{
      font-size:18px;
      font-weight:500;
      line-height:34px;
      color:#120D26;

    }
    .about p{
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }
    .buyercourse{
      font-size:14px;
      font-weight:500;
      line-height: 16px; 
      color:#ACACAC;
    }
    .buyerprice{
      margin-top: 10px;
      font-size: 24px;
      font-weight: 500;
      line-height: 21px;
      color:#127E60;
    }
    .privacy p{
      font-size: 14px;
      font-weight: 400;
    }
    
  .eventContainer{
    margin: 25px 0 25px 0;
  }
  
  .event h6{
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ACACAC
  }

  .eventName{
    font-weight : 500;
    font-size : 18px;
    padding: 0px 0px;
    line-height: 21px
  }
  .paragraph p{
    font-weight : 400;
    font-size : 16px;
    line-height: 22px;
  }
  .eventdata{
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
  }
  .eventtime{
     font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color:#ACACAC
  }
  .DateData{
    font-size:16px;
  }

  .timeData{
   font-size:12px;
   color:grey
  }

  .contentImg{
    margin-right: 12px;
    object-fit: contain;
  }

  .PageNotFound{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .PageNotFound h1{
    font-weight: 600;
  } 

.courseContainer{
        margin-top: 20px;
      }

.imagesContainer{
        margin-top: 10px;
        display: flex;
      }

.courseImg{
        border-radius: 10px;
      }

.courseVido{
        width: 130px;
        height: 106px;
        border-radius: 10px;
        margin: 0px 5px;
      }

.imagesContainer img{
        width: 135px;
        height: 135px;
        cursor: pointer;
        margin-right: 14px;
        transition: 400ms;
      }

.container{
  overflow: auto;
  display: flex;
  scroll-snap-type: x mandatory;
}
 /* .main-pd-wrapper {
    margin: 0px!important;
    /* padding: 20px 20px 100px 20px; }*/
  
.invoice-wrapper{
   padding:12px
}
.invoice-wrapper p{
  padding: 0!important;
  margin:0!important;
  font-size: 12px;
 
} 

table {
    width: 100%;
    table-layout: fixed;
}
td {
    vertical-align: top;
}
.table {
  width: 100%;
  margin-top: 15px;
  border-collapse: collapse;
}
.table th,.table td {
    padding: 15px;
    border: 1px solid #ddd;
}
.theader th {
  padding-left: 20px;
}
.h4-14 {
  font-size: 14px;
  }
.main-pd-wrapper p{
  margin: 0;
  padding: 0;
}
.cellStyle{
  border-bottom: 1px solid #000;
  border-right:1px solid #000;
  padding:4px
}
.background{
   background-image: linear-gradient(
  50deg,
  hsl(216deg 88% 81%) 0%,
  hsl(215deg 88% 82%) 8%,
  hsl(214deg 89% 82%) 17%,
  hsl(214deg 90% 83%) 26%,
  hsl(213deg 92% 84%) 36%,
  hsl(213deg 93% 84%) 46%,
  hsl(214deg 92% 83%) 57%,
  hsl(215deg 92% 81%) 68%,
  hsl(216deg 91% 80%) 79%,
  hsl(217deg 91% 78%) 90%,
  hsl(218deg 90% 77%) 100%
);
}

 .main-pd-wrapper {
            width: 1000px;
            margin: auto;
            padding: 20px 20px 100px 20px;
        }

        table {
            width: 100%;
            table-layout: fixed;
        }

        td {
            vertical-align: top;
        }

        .table {
            width: 100%;
            margin-top: 15px;
            border-collapse: collapse;
        }

        .table th,
        .table td {
            padding: 15px;
            border: 1px solid #ddd;
        }


        .theader th {
            padding-left: 20px;
        }

        .h4-14 {
            font-size: 14px;
        }

        p {
            margin: 0;
            padding: 0;
        }


  .instructor-wrapper p {
     margin: 0 !important;
    padding: 0!important;
  }
  .images {
    width: 75px;
    height: 75px;
    object-fit: cover;
}
.images img{
  width:100%;
  height: 100%;
}

/* / page css */
@media screen and (max-width: 1365px) {
  .bg {
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 0;
    margin-top: 0;
  }
  .coming-soon{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo-font {
    font-size: 5rem;
    margin-left: 1vw;
  }
 
}



.bg{
  background-image: url(https://i.imgur.com/Jt9EjiM_d.webp?maxwidth=1520&fidelity=grand);
    /* background-size: contain;
    background-repeat: round; */
    width: 100vw;
    height: 100vh;
    position: fixed;
    /* margin-left: -10vw;
    margin-top: -5vh; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.center{
  position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.coming-soon {
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  color: white;
  font-size: 2rem;
  text-align: center;
  padding-bottom: 50px;
}

.logo-font {
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-left: auto;
}

.typeface{
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  color: white;
  font-size: 1.6rem;
  border-left-color: white;
  border-left-width: 3px;
  border-left-style: solid;
  padding-left: 20px
}

/* something went wrong */
.something-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: white; /* Set your desired background color */
  padding: 20px; /* Adjust padding as needed */
  border-radius: 8px; /* Optional: Add rounded corners */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Optional: Add a subtle shadow */
  height: 100vh;
}

.icon-wrapper {
  margin-bottom: 10px;
}

.icon {
  font-size: 4em; 
  color: #FF0000; 
}

.error-text {
  font-size: 20px; 
  font-weight: bold; 
  color: #FF0000; 
}

.info-text {
  font-size: 18px; 
  color: #333;
}

.mainContainer {
  position: relative;
}

.carouselTop {
  /* Add your carousel styles for the top part here */
}

.blurredImageContainer {
  position: relative;
}

.blurredImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.clearImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.imageContainer:hover .clearImage {
  opacity: 1;
}
/* design for book slot */
.slotHeading{
   font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.slotCard{
  border:1px solid #FD6E47;
  border-radius: 10px;
  padding:5px;
}
.slotCardColor {
  border-radius: 10px;
  padding:8px;
  background-color: #127E60;
}

.slotCardh6{
  color:#FD6E47; 
  font-size:16px; 
}
.slotCardColor p,
.slotCardColor h6 {
  color: #fff;
}


.slotCard.selected {
  background-color: #127E60; 
  border: unset;
}
.slotCard.selected p,
.slotCard.selected h6{
  color: #fff !important;
}
.slotCarddisable{
 background-color: lightgray;
 border:unset
}

/* slot disable button */
.slotDisabledBtn{
    background-color: transparent;
    border:1px solid #127E60;
    border-radius: 10px;
  }
  .slotDisabledBtn h6
  {
    color: #127E60!important;
    font-weight: 500!important;
  }
