body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
}
h1, h2, h3 {
    color: #333;
    margin-top: 20px;
}
h2 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-top: 20px;
}
p {
    margin-bottom: 15px;
}
code {
    background-color: #f4f4f4;
    padding: 2px 5px;
    font-family: monospace;
}